/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IForcedOutageConfirmationForm,
  IRealTimeLog,
  IRealTimeLogEntryForm,
} from 'src/models/real-time-log.model';
import { RealTimeInterruptionEmailQualified } from 'src/constants/real-time-log.constant';
import { hasRole } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import { generateRealTimeLogEmailFormDetails } from 'src/helpers/real-time-log.helper';
import RealTimeLogForm from '../real-time-log-form/real-time-log-form.component';
import style from './real-time-log-edit.module.scss';
import ForcedOutageConfirmationForm, {
  ConfirmationSource,
} from '../real-time-log-form/forced-outage-email/forced-outage-confirmation-form';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';

type IProps = {
  defaultValues?: IRealTimeLog;
  isEditOpen: boolean | null;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  formRefEmail?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsFormOpen?: React.Dispatch<React.SetStateAction<boolean | null>>;
  handleSubmit: (data: IRealTimeLogEntryForm) => void;
  setIsChangeLogUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  changeLogUpdateComplete: boolean;
  setChangeLogUpdateComplete: React.Dispatch<React.SetStateAction<boolean>>;
  changeLogOnEditMode: boolean;
  setChangeLogOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setTripOnEdit: React.Dispatch<
    React.SetStateAction<{
      index: number;
      logSub: string;
      tripsTargets: string;
    } | null>
  >;
  handleConfirmEmailSubmit: (data: IForcedOutageConfirmationForm) => void;
  handleConfirmEmailClick: () => void;
  clearForcedOutageNotice: (logId: number) => void;
  isForcedOutageConfirmOpen: boolean;
  setIsForcedOutageConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sendNowLoading: boolean;
  isClearNoticeConfirmOpen: boolean;
  setIsClearNoticeConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  turnOffEmailLoading: boolean;
  isEnableConfirmOpen: boolean;
  setIsEnableConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  enableEmailNotice: (logId: number) => void;
  enableEmailLoading: boolean;
  isFromInProgress?: boolean;
  setConfirmationSource: React.Dispatch<
    React.SetStateAction<ConfirmationSource | null>
  >;
  confirmationSource: ConfirmationSource;
};

const RealTimeLogEditView: React.FC<IProps> = ({
  defaultValues,
  isEditOpen,
  loading,
  formRef,
  formRefEmail,
  handleOpen,
  setIsFormOpen,
  handleClick,
  handleSubmit,
  setIsChangeLogUpdate,
  changeLogUpdateComplete,
  setChangeLogUpdateComplete,
  changeLogOnEditMode,
  setChangeLogOnEditMode,
  setTripOnEdit,
  handleConfirmEmailSubmit,
  handleConfirmEmailClick,
  clearForcedOutageNotice,
  isForcedOutageConfirmOpen,
  setIsForcedOutageConfirmOpen,
  sendNowLoading,
  isClearNoticeConfirmOpen,
  setIsClearNoticeConfirmOpen,
  turnOffEmailLoading,
  isEnableConfirmOpen,
  setIsEnableConfirmOpen,
  enableEmailNotice,
  enableEmailLoading,
  // isFromInProgress,
  setConfirmationSource,
  confirmationSource,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const [isDirty, setIsDirty] = useState(false);
  return (
    <ATMModal
      open={isEditOpen ?? false}
      size="small"
      trigger={
        <ATMButton
          icon="edit"
          onClick={() => {
            handleOpen(true);
            if (setIsFormOpen) {
              setIsFormOpen(true);
            }
            // if (isFromInProgress) {
            //   actions.setIsFromInProgress(true);
            // }
          }}
          compact
          className={style.manageDropdown}
        />
      }
    >
      {hasRole(AccessRole.BPOR_SENDER) &&
      defaultValues &&
      Object.values(RealTimeInterruptionEmailQualified).includes(
        defaultValues.logCategory as RealTimeInterruptionEmailQualified
      ) ? (
        defaultValues.frcdOutgEmailSent !== false ? (
          <ATMModal.Header
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <>
              <span>{Lang.TTL_EDIT_ENTRY_LOG}</span>
              <span>
                <ATMButton
                  type="button"
                  secondary={defaultValues.frcdOutgEmailSent ? true : undefined}
                  positive={defaultValues.frcdOutgEmailSent ? undefined : true}
                  content={
                    defaultValues.frcdOutgEmailSent
                      ? Lang.LBL_FORCED_OUTAGE_NOTICE_RESEND
                      : Lang.LBL_FORCED_OUTAGE_NOTICE
                  }
                  onClick={() => {
                    setIsForcedOutageConfirmOpen(true);
                    setConfirmationSource(ConfirmationSource.SEND_RESEND_EMAIL);
                  }}
                />
                <ATMButton
                  type="button"
                  // icon="close"
                  content={Lang.LBL_TURNOFF}
                  negative
                  onClick={() => {
                    setIsClearNoticeConfirmOpen(true);
                    setConfirmationSource(ConfirmationSource.TURNED_OFF_EMAIL);
                  }}
                />
              </span>
            </>
          </ATMModal.Header>
        ) : (
          <ATMModal.Header
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <>
              <span>{Lang.TTL_EDIT_ENTRY_LOG}</span>
              <span>
                <ATMButton
                  type="button"
                  secondary
                  content={Lang.LBL_FORCED_OUTAGE_NOTICE_ENABLE}
                  onClick={() => {
                    setIsEnableConfirmOpen(true);
                    setConfirmationSource(ConfirmationSource.ENABLE_EMAIL);
                  }}
                />
              </span>
            </>
          </ATMModal.Header>
        )
      ) : (
        <ATMModal.Header>{Lang.TTL_EDIT_ENTRY_LOG}</ATMModal.Header>
      )}
      <ATMModal.Content style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <div>
          {(isForcedOutageConfirmOpen && defaultValues) ||
          (state.isFromInProgress && defaultValues) ||
          isClearNoticeConfirmOpen ||
          isEnableConfirmOpen ? (
            <ForcedOutageConfirmationForm
              formRefEmail={formRefEmail}
              handleConfirmEmailSubmit={handleConfirmEmailSubmit}
              handleConfirmEmailClick={handleConfirmEmailClick}
              isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
              setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
              logId={defaultValues?.logId as number}
              sendNowLoading={sendNowLoading}
              isClearNoticeConfirmOpen={isClearNoticeConfirmOpen}
              setIsClearNoticeConfirmOpen={setIsClearNoticeConfirmOpen}
              clearForcedOutageNotice={clearForcedOutageNotice}
              turnOffEmailLoading={turnOffEmailLoading}
              isOnEdit
              mailFormDetails={
                defaultValues
                  ? generateRealTimeLogEmailFormDetails(defaultValues)
                  : undefined
              }
              frcdOutgEmailSent={defaultValues?.frcdOutgEmailSent}
              enableEmailNotice={enableEmailNotice}
              isEnableConfirmOpen={isEnableConfirmOpen}
              setIsEnableConfirmOpen={setIsEnableConfirmOpen}
              enableEmailLoading={enableEmailLoading}
              handleOpen={handleOpen}
              confirmationSource={confirmationSource as any}
            />
          ) : (
            ''
          )}
          <RealTimeLogForm
            defaultValues={defaultValues}
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            handleClick={handleClick}
            setIsChangeLogUpdate={setIsChangeLogUpdate}
            changeLogUpdateComplete={changeLogUpdateComplete}
            setChangeLogUpdateComplete={setChangeLogUpdateComplete}
            setChangeLogOnEditMode={setChangeLogOnEditMode}
            loading={loading}
            setTripOnEdit={setTripOnEdit}
            isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
          />
        </div>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => {
            if (setIsFormOpen) {
              setIsFormOpen(false);
            }
            handleOpen(false);
            actions.setIsFromInProgress(false);
          }}
          disabled={loading}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading || changeLogOnEditMode}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default RealTimeLogEditView;
